<template>
	<div class="app-main__inner">
		<div class="container mb-3">
			<div class="flex justify-end mt-3 mb-3">
				<h4 class="text-xs font-bold text-gray-700 mr-1">Partner ID: </h4>
				<span class="font-bold text-xs house-theme-sm-head whitespace-nowrap">{{ this.firm.accounting_partner_id }}</span>
			</div>

			<div class="mb-10">
				<div class="row mb-2 mt-3 mb-3">
					<div class="col-md-12 flex justify-between">
						<div>
							<h2 class="font-semibold text-base text-gray-900 mb-2">{{ $t('accountant') }}</h2>
						</div>
						<div class="flex align-items-center justify-end space-x-6">
							<!-- <span data-cy='connectedCom' class="font-bold mr-2 p-1 rounded border theme-blue text-xs whitespace-nowrap cursor-pointer font-light" @click="connectedCompany">{{ $t('connected') }}</span> -->
							<div class="form-grou">
								<div class="input-icons">
									<i class="iconly-brokenSearch"></i>
									<input type="text" @input="searchInputTrigger"
										   class="text-sm h-8 form-control custom-search"
										   v-bind:placeholder="$t('search')">
								</div>
							</div>
						</div>
					</div>
				</div>
				<table style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered">
					<thead>
					<tr>
						<th class="text-xs font-bold"><span class="iconly-boldProfile"></span></th>
						<th class="text-xs text-gray-800 font-bold">
							<div class="d-flex justify-content-between">{{ $t('name') }}<span><i
								@click="ascendingOrderName" class="lnr-arrow-up cursor-pointer"></i><i
								@click="descendingOrderName" class="lnr-arrow-down cursor-pointer"></i></span></div>
						</th>
						<th class="text-xs text-gray-800 font-bold">{{ $t('role') }}</th>
						<th class="text-xs text-gray-800 font-bold">{{ $t('email') }}</th>
						<th class="text-xs text-gray-800 font-bold">
							<div class="d-flex justify-content-between">{{ $t('dateAdded') }}<span><i
								@click="sortDateAscending" class="lnr-arrow-up cursor-pointer"></i><i
								@click="sortDateDescending" class="lnr-arrow-down cursor-pointer"></i></span></div>
						</th>
						<th class="text-xs text-gray-800 font-bold">{{ $t('management') }}</th>
					</tr>
					</thead>
					<tbody v-loading='vLoadingFirm'>
					<tr v-for="(manager, i) in GET_FIRM_ACCOUNTANT" :key="manager.id">
						<td class="house-theme-sm-head">{{ i + 1 }}</td>
						<td class="text-xs font-light">
							{{ manager.first_name + ' ' + manager.last_name | capitalizeEachString }}
						</td>
						<td class="text-xs font-light">Accountant</td>
						<td class="text-xs font-light">{{ manager.email }}</td>
						<td class="text-xs font-light">{{ manager.date_joined.split('T')[0] }}</td>
						<td class="flex space-x-2">
							<span @click="specifCompany(manager)"
								  class="text-base cursor-pointer iconly-brokenAdd-User icon-color"></span>
							<span @click="getFirmUserID"
								  class="text-base cursor-pointer iconly-brokenEdit icon-color"></span>
							<!-- <span class="text-base cursor-pointer iconly-brokenPaper-Plus icon-color"></span> -->
							<span @click="removeAccount(manager.id)"
								  class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>
							<!-- <span>
								<svg class="h-5 w-5 cursor-pointer icon-color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								<path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
								</svg>
							</span>
							<span>
								<svg class="h-5 w-5 cursor-pointer icon-color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
								</svg>
							</span>
							<span>
								<svg class="h-5 w-5 icon-color cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								<path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
								</svg>
							</span>
							<span>
								<svg class="h-5 w-5 cursor-pointer icon-color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								<path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
								</svg>
							</span> -->
						</td>
					</tr>
					<!-- <tr v-if="allAccountants.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
					</tbody>
				</table>
				<div class="float-right mb-5" v-if="history.MetaInformation">
					<paginate
						:page-count="history.MetaInformation['@TotalPages']"
						:click-handler="changePageNumber"
						:prev-text="$t('prev')"
						:next-text="$t('next')"
						:container-class="'pagination'">
					</paginate>
				</div>
			</div>
		</div>


		<el-dialog
			:visible.sync="paymentCardModal"
			width="40%"
			:handleClose="handleClose"
			center>
			<div class="container">
				<div class="row">
					<div id="card-element" class="col-md-12">

					</div>
				</div>
			</div>
		</el-dialog>

		<Notification/>
	</div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import Paginate from 'vuejs-paginate'
import bus from '../../bus'
import debounce from 'lodash/debounce'
import {Dialog} from 'element-ui'

Vue.use(Dialog)

export default {
	name: 'billing-dashboard',
	components: {
		Notification: () => import('./../../customComponent/NotificationAlert'),
		paginate: Paginate,
	},
	data() {
		return {
			PaymentType: null,
			partnerID: '',
			firm: {
				name: '',
				zip_code: '',
				city: '',
				phone: '',
				billing_address: '',
				email: '',
				invoice_email: '',
				affiliate_type: '',
				bank: '',
				bankgiro_number: '',
			},
			allCompanies: [],
			allAccountants: [],
			copyAllAccountant: [],
			history: [],
			updating: false,
			loadingFirm: false,
			paymentCardModal: false,
			vLoadingFirm: true,

		}
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_USER: 'customer/GET_USER',
			GET_USERS_COMPANY: 'accountant/GET_USERS_COMPANY',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_COMPANY_BILLING: 'customer/GET_COMPANY_BILLING',
			GET_PAYMENT_TYPE: 'customer/GET_PAYMENT_TYPE',
			GET_FIRM: 'house/GET_FIRM',
			GET_FIRM_ACCOUNTANT: 'house/GET_FIRM_ACCOUNTANT',
			GET_USER_FIRM_ID: 'customer/GET_USER_FIRM_ID',
		})
	},
	created() {
		this.getAuthUser()
	},
	mounted() {
		// const { partner } = this.$route.params
		// if (partner) {
		//     this.partnerID = partner
		//     this.getFirmInformation(partner)
		//     this.getFirmAccountants(partner)
		//     // this.getConnectCompany(partner)
		//     // bus.$emit('connected-com', partner)
		// }
		// bus.$on('assign', _ => {
		//     this.getFirmAccountants(partner)
		// })
	},
	methods: {
		searchInputTrigger: debounce(function (event) {
			if (event.target.value !== '') {
				this.filterAccountants(event)
			} else {
				this.$store.commit('house/FILTER_ACCOUNTANT_ALL')
			}
		}, 500),
		filterAccountants(event) {
			this.$store.commit('house/FILTER_ACCOUNTANTS', event.target.value)
		},
		handleClose() {
			this.paymentCardModal = false
		},
		getAuthUser() {
			const URL = this.$services.endpoints.GET_USER
			this.$store.dispatch('customer/getAuthUser', {URL})
				.then(res => {
					if (res.data.success) {
						this.getFirmInformation(res.data.data.user.accounting_firm_id)


					}
				}).catch(err => {
				// console.log(err)
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getAuthUser()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out-accountant')
							})
					}
				}
			})
		},
		getConnectCompanies() {
			this.$store.dispatch('accountant/getConnectCompany', {URL: this.$services.endpoints.IN_HOUSE_GET_FIRM_COMPANIES + `${JSON.parse(partnerId).partner}`})
		},
		getFirmInformation(partnerID) {
			this.loadingFirm = true
			let URL = this.$services.endpoints.IN_HOUSE_UPDATE_FIRM + `${partnerID}/`
			this.$store.dispatch('house/getFirmInformation', {URL:URL})
				.then(res => {
					this.loadingFirm = false
					if (res.data.success) {
						this.firm = res.data.data
					}
				}).catch(err => {
				this.loadingFirm = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getFirmInformation(partnerID)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out-accountant')
							})
					}
				}
			})
		},
		getFirmAccountants(partnerID) {
			this.vLoadingFirm = true
			this.$store.dispatch('house/getFirmAccountants', {URL: this.$services.endpoints.IN_HOUSE_SINGLE_FIRM + `${partnerID}/`})
				.then(_ => {
					this.vLoadingFirm = false
				}).catch(err => {
				this.vLoadingFirm = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getFirmAccountants(partnerID)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out-accountant')
							})
					}
				}
			})
		},
		connectedCompany() {
			bus.$emit('connected-com', this.firm.accounting_partner_id)
			// this.$store.commit('OPEN_MODAL_UTIL')
			this.$store.commit('utility/OPEN_INVITE_MODAL')
		},
		specifCompany(chosenAccountant) {
			const d = {
				chosenAccountant,
				accountant: this.GET_FIRM_ACCOUNTANT
			}
			bus.$emit('specific', d)
			this.$store.commit('utility/OPEN_MODAL_UTIL')
		},
		getFirmUserID() {
			const {partner} = this.$route.params
			const {firm} = this.$route.query
			if (partner) {
				this.$store.commit('customer/SET_USER_FIRM_ID', {partner, firm})
				setTimeout(() => {
					window.open('/accountant', '_blank')
				}, 1000)
			}
		},
		ascendingOrderName() {
			// this.$store.commit('house/ASCENDING_ORDER_NAME')
		},
		descendingOrderName() {
			// this.$store.commit('house/DESCENDING_ORDER_NAME')
		},
		sortDateAscending() {
			// this.$store.commit('house/SORT_DATE_ASC')
		},
		sortDateDescending() {
			// this.$store.commit('house/SORT_DATE_DESC')
		},
		updateUserCompany() {
			if (this.firm.name === '') {
				this.$services.helpers.notification(this.$t('requireCompanyNumber'), 'error', this)
				return false
			}
			if (this.firm.billing_address === '') {
				this.$services.helpers.notification(this.$t('requireCompanyAddress'), 'error', this)
				return false
			}

			if (!this.$services.helpers.validateEmailAddress(this.firm.email)) {
				this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
			}

			if (this.validateCompanyNumber(this.firm.company_id)) {
				this.$services.helpers.notification(this.$t('invalidCompany'), 'error', this)
				return false
			}
			if (this.firm.phone === '') {
				this.$services.helpers.notification(this.$t('requirePhonenumber'), 'error', this)
				return false
			}
			if (this.firm.zip_code === '') {
				this.$services.helpers.notification(this.$t('requireZipCode'), 'error', this)
				return false
			}
			if (this.firm.city === '') {
				this.$services.helpers.notification(this.$t('requireCity'), 'error', this)
				return false
			}
			this.updating = true
			if (this.firm.accounting_partner_id) {
				let payload = {}
				Object.keys(this.firm).map(item => {
					if (this.firm[item]) {
						payload[item] = this.firm[item]
					}
				})
				this.$store.dispatch('house/updateFirmInfo', {
					data: payload,
					URL: this.$services.endpoints.IN_HOUSE_UPDATE_FIRM + `${this.firm.accounting_partner_id}/`
				})
					.then(res => {
						this.updating = false
						if (res.data.success) {
							this.$services.helpers.notification(this.$t('successCompanyUpdated'), 'success', this)
						}
					}).catch(err => {
					this.updating = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.updateUserCompany()
								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out-accountant')
								})
						}
						if (err.response.data.code === 500) {
							this.$services.helpers.notification(err.response.data.data, 'error', this)
						}
					}
				})
			}
		},
		validateCompanyNumber(number) {
			if (!number.includes('-')) {
				return true
			}
			if (number.split('-')[0].length === 6 && number.split('-')[1].length === 4) {
				return false
			}
			return true
		},
		removeAccount(id) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			const {partner} = this.$route.params
			this.$store.dispatch('house/removeAccount', {URL: this.$services.endpoints.IN_HOUSE_UPDATE_FIRM + `${partner}/${id}`})
				.then(res => {
					if (res.status === 200) {
						this.getFirmAccountants(partner)
						this.$services.helpers.notification(this.$t('accountantRemove'), 'success', this)
					}
				}).catch(err => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.removeAccount(id)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out-accountant')
							})
					} else {
						this.$services.helpers.notification(err.response.data.data, 'error', this)
					}
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
@import "~vue-tour/dist/vue-tour.css";

.custom-btn-pay {
	background-color: #F167CA !important;
	border-color: #F167CA !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all .2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2CDCA7 !important;
	border-color: #2CDCA7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.col-form-label {
	padding-top: 1rem !important;
	// font-weight: 800;
	// font-size: 14px !important;
	white-space: nowrap;
}

.form-group {
	margin-bottom: 0.4rem !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.5rem !important;
}

.form-control {
	border: 1px solid $form-border !important;
	// height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.theme-blue {
	color: $theme-secondary-color;
	border: 0.5px solid $theme-secondary-color !important;
}

.input-icons i {
	position: absolute;
	margin: 7px;
}

.input-icons {
	width: 100%;
	// margin-bottom: 10px;
}

.custom-search {
	padding-left: 1.5rem !important;
}
</style>
